import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import detectingMobileDevice from "./detectingMobileDevice";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
/*
 *  @description Component
 *  Gestion des select rendu différent pour les mobiles,
 *  afin d'optimiser l'intégration
 */

const SubTitle = styled.div`
  font-size: 0.8em;
  color: #484848;
`;

const RenderSelectFieldCommand = ({
  items,
  input,
  label,
  meta,
  helperText,
  classes,
  style,
  id,
  dataCy,
  placeHolder
}) => {
  let displayError = meta.submitFailed && meta.error ? true : false;
  let getSubTitle = items => {
      return (
        <React.Fragment>
          <SubTitle>
          {items.map((item, index) => {
            return (
              <Typography key={index}>
              {item.quantity}x {item.description} {item.color}{" "} {item.size}
              </Typography>
            )
          })}
          </SubTitle>
        </React.Fragment>
      );
  };
  if (detectingMobileDevice) {
    return (
      <FormControl error={displayError} style={style}>
        <InputLabel>{label}</InputLabel>
        <NativeSelect {...input} id={id}>
          <option value="" disabled>
            {placeHolder ? placeHolder : ""}
          </option>
          {items.map((item, index) => {
            return (
              <option
                key={index}
                value={item.code || item.id}
                data-cy={`menu-item-${dataCy}-${index}`}
              >
                {item.color && item.size
                  ? `${item.name} ${item.color} / ${item.size}`
                  : item.name}
              </option>
            );
          })}
        </NativeSelect>
        <FormHelperText>{displayError ? meta.error : null}</FormHelperText>
      </FormControl>
    );
  } else {
    return (
      <FormControl style={style} error={displayError}>
        <InputLabel>{label}</InputLabel>
        <Select
          {...input}
          displayEmpty
          error={displayError}
          data-cy={`select-${dataCy}`}
        >
          <MenuItem value="" disabled>
            {placeHolder ? placeHolder : ""}
          </MenuItem>
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.code || item.id}
                data-cy={`menu-item-${dataCy}-${index}`}
              >
                {
                  <div>
                    {item.name}
                    {getSubTitle(item.items)}
                  </div>
                }
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{displayError ? meta.error : null}</FormHelperText>
      </FormControl>
    );
  }
};

export default RenderSelectFieldCommand;
