import { SubmissionError } from 'redux-form'

import translate from '../../../i18n/translate';

const submitExchangeAdressForm = (values, dispatch, props) => {
  if (!values.street) {
    throw new SubmissionError({
      street: translate("required")
    })
  }
  if (!values.city) {
    throw new SubmissionError({
      city: translate("required")
    })
  }
  if (!values.postalCode) {
    throw new SubmissionError({
      postalCode: translate("required")
    })
  }
  if (!values.countryCode) {
    throw new SubmissionError({
      countryCode: translate("required")
    })
  }
  props.handleSwipeableIndex(1)
}

export default submitExchangeAdressForm
