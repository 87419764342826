import trad from "../../../../../i18n/messages";

const HAUT = "HAUT"
const BAS = "BAS"
const CHAUSSURES = "CHAUSSURES"
const ACCESSOIRES = "ACCESSOIRES"

export const productTypes = () => {
  return ({ 
    "Accessoires" : ACCESSOIRES,
    "Accessoires autres" : ACCESSOIRES,
    "Accessoires textiles" : ACCESSOIRES,
    "Chaussures" : CHAUSSURES,
    "Chemises" : HAUT,
    // TODO: Groupe pantalon Veste pour déterminer
    "Costumes" : "",
    // "Emballage" : "",
    // "Gift" : "",
    // "Jersey" : "",
    "Jupes" : BAS,
    "Mailles et Sweats" : HAUT,
    "Manteaux" : HAUT,
    "Manteaux et Vestes" : HAUT,
    "Pantalons" : BAS,
    "Pantalons et Shorts" : BAS,
    "Pulls et Sweats" : HAUT,
    "Robes" : HAUT,
    "Sous-vêtements et Maillots de bain" : BAS,
    "T-shirts et Polos" : HAUT,
    "Tshirts" : HAUT
  })
}

export const returnReasons = (lang) => {
  return (
      [
        {
          name: trad[lang]['too_big'],
          code: 'too_big',
          code_loki: 'TOO_LARGE',
        },
        {
          name: trad[lang]['too_small'],
          code: 'too_small',
          code_loki: 'TOO_SMALL',
        },
        {
          name: trad[lang]['color_is_not_suitable'],
          code: 'color_is_not_suitable',
          code_loki: 'DISLIKE_COLOR',
        },
        {
          name: trad[lang]['material_is_not_suitable'],
          code: 'material_is_not_suitable',
          code_loki: 'DISLIKE_MATERIAL',
        },
        {
          name: trad[lang]['ordered_two_different_sizes'],
          code: 'ordered_two_different_sizes',
          code_loki: 'MULTIPLE_SIZES_ORDERED',
        },
        {
          name: trad[lang]['ordered_two_different_colors'],
          code: 'ordered_two_different_colors',
          code_loki: 'MULTIPLE_COLORS_ORDERED',
        },
        {
          name: trad[lang]['damaged_item'],
          code: 'damaged_item',
          code_loki: 'DAMAGED_ITEM',
        },
        // NEW
        {
          name: trad[lang]['changed_mind'],
          code: 'other',
          code_loki: 'CHANGED_MIND',
        },
        {
          name: trad[lang]['too_late'],
          code: 'other',
          code_loki: 'TOO_LATE',
        },
        {
          name: trad[lang]['wrong_item'],
          code: 'incorrect_item',
          code_loki: 'WRONG_ITEM',
        }
      ]
  );
}

export const returnSecondaryReasons = (reasonCode, key, lang) => {
  if (reasonCode === 'TOO_LARGE' || reasonCode === 'TOO_SMALL') {

    var generalReasons = reasonCode === 'TOO_LARGE' ? [...returnZoneGeneralCode(lang)['TOO_LARGE']] : [...returnZoneGeneralCode(lang)['TOO_SMALL']]
    switch (key) {
      case HAUT:
        return [
          ...returnZoneHautCode(lang),
          ...generalReasons
        ]
      case BAS:
        return [
          ...returnZoneBasCode(lang),
          ...generalReasons
        ]
      case CHAUSSURES:
        return [
          ...returnZoneChaussuresCode(lang),
          ...generalReasons
        ]
      default:
        return []
    }
  }
  return []
}

export const returnZoneHautCode = (lang) => {
  return (
      [
        {
          name: trad[lang]['col'],
          code: 'col',
          code_loki: 'COL',
        },
        {
          name: trad[lang]['epaules'],
          code: 'epaules',
          code_loki: 'EPAULES',
        },
        {
          name: trad[lang]['poitrine'],
          code: 'poitrine',
          code_loki: 'POITRINE',
        },
        {
          name: trad[lang]['aisselles'],
          code: 'aisselles',
          code_loki: 'AISSELLES',
        },
        {
          name: trad[lang]['ventre'],
          code: 'ventre',
          code_loki: 'VENTRE',
        },
        {
          name: trad[lang]['longueur_manches'],
          code: 'longueur_manches',
          code_loki: 'LONGUEUR_MANCHES',
        },
        {
          name: trad[lang]['largueur_manches'],
          code: 'largueur_manches',
          code_loki: 'LARGUEUR_MANCHES',
        },
      ]
  );
}

export const returnZoneBasCode = (lang) => {
  return (
      [
        {
          name: trad[lang]['taille'],
          code: 'taille',
          code_loki: 'TAILLE',
        },
        {
          name: trad[lang]['fourche'],
          code: 'fourche',
          code_loki: 'FOURCHE',
        },
        {
          name: trad[lang]['hanches'],
          code: 'hanches',
          code_loki: 'HANCHES',
        },
        {
          name: trad[lang]['cuisses'],
          code: 'cuisses',
          code_loki: 'CUISSES',
        },
        {
          name: trad[lang]['mollets'],
          code: 'mollets',
          code_loki: 'MOLLETS',
        },
        {
          name: trad[lang]['chevilles'],
          code: 'chevilles',
          code_loki: 'CHEVILLES',
        },
      ]
  );
}

export const returnZoneChaussuresCode = (lang) => {
  return (
      [
        {
          name: trad[lang]['talon'],
          code: 'talon',
          code_loki: 'TALON',
        },
        {
          name: trad[lang]['orteils'],
          code: 'orteils',
          code_loki: 'ORTEILS',
        },
      ]
  );
}

export const returnZoneGeneralCode = (lang) => {
  return (
    {
      'TOO_LARGE': [
        {
          name: trad[lang]['longueur_totale'],
          code: 'longueur_totale',
          code_loki: 'LONGUEUR_TOTALE',
        },
        {
          name: trad[lang]['globalement_trop_large'],
          code: 'globalement_trop_large',
          code_loki: 'GLOBALEMENT_TROP_LARGE',
        },
      ],
      'TOO_SMALL': [
        {
          name: trad[lang]['longueur_totale'],
          code: 'longueur_totale',
          code_loki: 'LONGUEUR_TOTALE',
        },
        {
          name: trad[lang]['globalement_trop_serre'],
          code: 'globalement_trop_serre',
          code_loki: 'GLOBALEMENT_TROP_SERRE',
        },
      ]
    }
  );
}
