import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import RenderTextField from "../../utilis/renderField/RenderTextField";
import submit from "./submit";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ButtonLoading from "./ButtonLoading";

import translate from '../../../i18n/translate';
import AsphalteButton from "../../utilis/AsphalteButton";

const ValidateButtonContainer = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4em;
  font-weight: 200;
  margin-bottom: 30px;
`;

const noteStyle = {
  fontSize: "13px",
  fontWeight: "300",
  paddingTop: "5px"
};

class AuthStep extends Component {
  state = {
    displayLoader: false
  };

  componentDidMount() {
    this.props.initialize(queryString.parse(this.props.location.search));
  }

  render() {
    const { handleSubmit, loadingAuth } = this.props;
    return (
      <form onSubmit={handleSubmit(submit)}>
          <Title>{translate('login')}</Title>
        <Field
          id="recipient-criteria"
          name="recipientCriteria"
          label={translate('email_phone')}
          style={{ width: "100%" }}
          component={RenderTextField}
        />
        <Field
          id="external-reference"
          name="externalReference"
          label={translate('order_number')}
          type="text"
          style={{ width: "100%", marginTop: 20 }}
          component={RenderTextField}
        />
        <ValidateButtonContainer>
          {loadingAuth ? (
            <ButtonLoading />
          ) : (
            <AsphalteButton
              id="login-button"
              type="submit"
              variant="contained"
            >
            {translate("submit")}
            </AsphalteButton>
          )}
        </ValidateButtonContainer>
        <div>
          <p style={noteStyle}>
            {translate("login_comment")}
          </p>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  loadingAuth: state.login.loadingAuth
});

AuthStep = reduxForm({
  form: "login",
  destroyOnUnmount: false
})(AuthStep);

export default connect(mapStateToProps)(withRouter(AuthStep));
