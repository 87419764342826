import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

class internalError extends Component {
  render() {
    return(
      <div style = {{backgroundColor: '#fff', paddingBottom: '40px'}}>
      <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            style={{marginTop: '5%'}}>
              <div style={{fontSize: '1em'}}>
                <h1>Erreur - 500</h1>
              </div>
              <p>{"Cette page est temporairement indisponible"}</p>
              <p>{"Pas de panique nos agents sont sur le coup"}</p>
        </Grid>
      </div>
    )
  }
}

export default withRouter(internalError)
