import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { submit } from "redux-form";

import SwipeableViews from "react-swipeable-views";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";

import SelectBookingDate from "./SelectBookingDate";
import Confirmation from "./Confirmation";
import Email from "./Email";

import { closeConfirmationCourierModal, resetState } from "../actions";

import translate from '../../../i18n/translate';
import AsphalteButton from "../../utilis/AsphalteButton";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfirmationCourierModal extends Component {
  state = {
    swipeableIndex: 0
  };

  handleSwipeableIndex = index => {
    this.setState({
      swipeableIndex: index
    });
  };

  render() {
    const {
      history,
      successCreateReturn,
      displayConfirmationCourierModal,
      closeConfirmationCourierModal,
      submit,
      resetState
    } = this.props;
    return (
      <div>
        <Dialog
          open={displayConfirmationCourierModal}
          TransitionComponent={Transition}
          fullWidth={true}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <SwipeableViews index={this.state.swipeableIndex} disabled>
            <SelectBookingDate
              handleSwipeableIndex={this.handleSwipeableIndex}
            />
            <Email handleSwipeableIndex={this.handleSwipeableIndex} />
            <Confirmation />
          </SwipeableViews>
          {!successCreateReturn ? (
            <DialogActions>
              {this.state.swipeableIndex === 0 ? (
                <AsphalteButton
                  id="button-cancel-confirmation-modal-courier"
                  onClick={closeConfirmationCourierModal}
                >
                   {translate("return")}
                </AsphalteButton>
              ) : (
                ""
              )}
              {this.state.swipeableIndex === 1 ? (
                <React.Fragment>
                  <AsphalteButton
                    id="button-cancel-confirmation-modal-courier"
                    onClick={() => {
                      this.handleSwipeableIndex(0);
                    }}
                  >
                     {translate("return")}
                  </AsphalteButton>
                  <AsphalteButton
                    id="button-validate-confirmation-modal-courier"
                    onClick={() => {
                      submit();
                    }}
                  >
                    Valider
                  </AsphalteButton>
                </React.Fragment>
              ) : (
                ""
              )}
              {this.state.swipeableIndex === 2 ? (
                <AsphalteButton
                  onClick={closeConfirmationCourierModal}
                >
                  {translate("try_again")}
                </AsphalteButton>
              ) : (
                ""
              )}
            </DialogActions>
          ) : (
            <DialogActions style={{ justifyContent: "center" }}>
              <AsphalteButton
                id="return-to-connexion-page"
                onClick={() => {
                  resetState();
                  history.push("/");
                }}
              >
                {translate("return_login")}
              </AsphalteButton>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    displayConfirmationCourierModal:
      state.returnItem.displayConfirmationCourierModal,
    successCreateReturn: state.returnItem.successCreateReturn,
    shipmentType: state.returnAdress.shipmentType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeConfirmationCourierModal: () =>
      dispatch(closeConfirmationCourierModal()),
    resetState: () => dispatch(resetState()),
    submit: () => dispatch(submit("emailFormCourier"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConfirmationCourierModal));
