import React, { Component } from "react";
import RenderTextField from "../../../../utilis/renderField/RenderTextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import submit from "./submit";

import translate from '../../../../../i18n/translate';
import AsphalteButton from "../../../../utilis/AsphalteButton";

class CommentStep extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(submit)}>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Typography
            align="center"
            style={{ fontSize: "1.4em", marginTop: 20 }}
          >
            {translate('add_comment')}
          </Typography>
          <Field
            id="custom-return-reason"
            name="customReturnReason"
            label="Commentaire"
            multiline={true}
            variant="outlined"
            style={{ width: "80%", marginTop: 20 }}
            rows="2"
            component={RenderTextField}
            type="text"
          />
          <AsphalteButton
            id="button-validate-comment"
            type="submit"
            variant="outlined"
            style={{ marginTop: 40 }}
          >
            Terminer
          </AsphalteButton>
        </Grid>
      </form>
    );
  }
}

const causeStepSelector = formValueSelector("causeStep");
const commentSelector = formValueSelector("commentStep");

const mapStateToProps = state => {
  return {
    returnReason:
      causeStepSelector(state, "returnReason") || {
        name: '',
        code: 'incorrect_item',
        code_loki: 'WRONG_ITEM',
      },
    returnSecondaryReason:
      causeStepSelector(state, "returnSecondaryReason") || [],
    customReturnReason: commentSelector(state, "customReturnReason"),
    desiredItem: state.returnItem.desiredItem,
    returnNatureCode: state.returnItem.returnNatureCode,
    index: state.returnItem.selectedItem.index
  };
};

CommentStep = reduxForm({
  form: "commentStep"
})(CommentStep);

export default connect(mapStateToProps)(CommentStep);
