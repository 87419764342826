import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import translate from '../../i18n/translate';
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { setCarrierCode, setShipmentType } from "./actions";
import AsphalteButton from "../utilis/AsphalteButton";
import { getPickupPoints } from "../../api/api";
import { Collapse, DialogActions, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, Typography } from "@material-ui/core";
import { formValueSelector } from "redux-form";
import store from "../../store";

/**
 * @function Transition
 * @param {object} - props
 * @return { Component }
 * @description Ajoute un effet d'entré et de sortie  pour la modal
 */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

/**
 * Composant permettant à l'utilisateur de choisir entre faire un
 * retour / échange par coursier ou par voie postale.
 * cette modal dialog s'ouvre uniquement dans le cas ou l'utilisateur souhaite
 * faire une retour ou un échange depuis une zone déservie par les coursiers sympl
 */

const Modal = (props) => {

  const {
    open,
    history,
    setShipmentType,
    availableBookingDates,
    handleClose,
    setCarrierCode
  } = props;

 

  const [pickupPoints, setPickupPoints] = useState([])
  const [loading, setLoading] = useState(true)
  const [choice, setChoice] = useState("pickup")
  const [postalCode, setPostalCode] = useState("")

  useEffect(() => {
      // init default choice
      setCarrierCode("MONDIAL_RELAY")
      setShipmentType("carrier");

      const returnAdressSelector = formValueSelector('returnAdress')
      const returnAddress = returnAdressSelector(store.getState(), 'street', 'city', 'postalCode', 'countryCode')

      if (returnAddress.postalCode) {
        setPostalCode(returnAddress.postalCode)
      }

      const load = async () => {
          let res = await getPickupPoints(returnAddress)
          setPickupPoints(res.data?.pickupPoints.slice(0,5))
          setLoading(false)
      }
      if (open) {
          setLoading(true)
          load()
      }

      return () => {
       setPickupPoints([])
      }
  }, [open,setCarrierCode,setShipmentType])

  const handleChoice = (data) => {
    setChoice(data)
    switch (data) {
      case "pickup":
        setCarrierCode("MONDIAL_RELAY")
        setShipmentType("carrier");
        break;
      case "colissimo":
        setCarrierCode("COLISSIMO")
        setShipmentType("carrier");
        break;   
      case "courier":
        setCarrierCode(null)
        setShipmentType("courier");
        break;

      default:
        break;
    }
  }

    return (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth="sm"
          fullWidth={true}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ textAlign: "center" }}>
          {translate("carrier_choice")}
          </DialogTitle>
          <DialogContent dividers>
            <List>
              <ListItem>
              <ListItemText><Typography style={{fontWeight: "bold", textTransform: "uppercase"}}>Mondial Relay</Typography></ListItemText>
                  <ListItemSecondaryAction>
                    {/* // HERE ADD icons*/}
                      <Radio color="primary"  onClick={() => handleChoice("pickup")} checked={choice === "pickup"}/>
                  </ListItemSecondaryAction>
          
              </ListItem>
              {choice === 'pickup' &&(<Collapse in={true} timeout="auto" unmountOnExit >
                { loading 
                    ? (<DialogContentText>{translate("loading")}</DialogContentText>) 
                    : (
                    <List>
                        <ListItem><a rel="noreferrer" href={`https://www.mondialrelay.fr/trouver-le-point-relais-le-plus-proche-de-chez-moi/?codePays=FR&codePostal=${postalCode}&ville=`} target="_blank">{translate("pickup_on_map")}</a></ListItem>
                        {pickupPoints.map((p) => {
                            return (
                                <ListItem key={p.id}>
                                    <ListItemText primary={p.name} secondary={
                                        <React.Fragment>
                                        <Typography component="span" style={{fontWeight: "bold"}}>
                                        {p.distance}m 
                                        </Typography>
                                        {` - ${p.street} ${p.postalCode} ${p.city} `}
                                        </React.Fragment>
                                    } />
                                </ListItem>
                            )
                        })}
                    </List>
                    )
                }
                </Collapse>)}
                <Divider />
                <ListItem>
                  <ListItemText><Typography style={{fontWeight: "bold", textTransform: "uppercase"}}>Colissimo</Typography></ListItemText>
                    <ListItemSecondaryAction>
                        <Radio color="primary" onClick={() => handleChoice("colissimo")} checked={choice === "colissimo"}/>
                    </ListItemSecondaryAction>
            
                </ListItem>
              </List>
              <DialogActions>
                <AsphalteButton
                  id="send-by-carrier-button"
                  onClick={() => {
                    history.push("/return-items");
                  }}
                  >
                    {translate("submit")}
                </AsphalteButton>
              </DialogActions>
          </DialogContent>
        </Dialog>
    );
}

const mapDispatchToProps = state => {
  return {
    availableBookingDates: state.returnAdress.availableBookingDates
  };
};

const mapDispatchToProsps = dispatch => {
  return {
    setShipmentType: shipmentType => dispatch(setShipmentType(shipmentType)),
    setCarrierCode: carrierCode => dispatch(setCarrierCode(carrierCode))
  };
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProsps
)(withRouter(Modal));
