import translate from '../../../i18n/translate';

const mappingErrors = {
  "207016" : translate('order_not_found'),
  "207050": translate('order_cannot_be_returned'),
  "207081": translate('country_not_available'),
  "224001": translate('problem_creating_return')
}

export default mappingErrors
