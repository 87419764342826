import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
`;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
    Sentry.captureException(err);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <div>
            <h1>
              Oupss, il y a eu un souci pas de panique notre équipe est sur le
              coup !
            </h1>
          </div>
        </Container>
      );
    }
    return this.props.children;
  }
}
