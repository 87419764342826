/**
* @function validate
* @param {object} - Object contenant les valeurs des champs du formulaire
* @description validation du formulaiure ReturnAdress
* @return {object} - contenant les champs et message d'erreur
*/

import translate from '../../i18n/translate';

const validate = (values) => {
  let errors = {}
  if (!values.contactEmail) {
    errors.contactEmail = translate('required')
  }
  if (!values.street) {
    errors.street = translate('required')
  }
  if (!values.city) {
    errors.city = translate('required')
  }
  if (!values.postalCode) {
    errors.postalCode = translate('required')
  }
  if (!values.countryCode) {
    errors.countryCode = translate('required')
  }
  return errors
}

export default validate;
