import React, { Component } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import translate from '../../../i18n/translate';

/**
 * @description Page de Confirmation pour les envois par coursier
 */
class courierConfirmation extends Component {
  render() {
    return (
      <div>
        {this.props.loadingCreateReturn ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "11%" }}
          >
            <CircularProgress id="circle-loader" />
          </Grid>
        ) : (
          <div>
            <DialogTitle style={{ textAlign: "center" }}>
              {this.props.successCreateReturn ? (
                <span id="success-create-return-label">
                  {translate("request_received")}
                </span>
              ) : (
                <span id="error-create-return-label">
                  {translate("booking_courier_error")}
                </span>
              )}
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText id="alert-dialog-slide-description">
                {this.props.successCreateReturn ? (
                  <span>
                    {translate("booking_courier_confirm")}
                  </span>
                ) : (
                  <span>
                    {translate("booking_courier_issues")}
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    successCreateReturn: state.returnItem.successCreateReturn,
    loadingCreateReturn: state.returnItem.loadingCreateReturn,
    shipmentType: state.returnAdress.shipmentType
  };
};

export default connect(mapStateToProps)(courierConfirmation);
