import { SubmissionError } from 'redux-form'
import { setActionItem } from '../../../actions';
import { closeReturnModal, resetSwipeableIndex, expandCard } from '../../../actions';

import translate from '../../../../../i18n/translate';

/**
* @function submit
* @param {object} value - current value
* @param {function} dispatch - redux dispatch
* @param {values} object - all form values
* @description validation du formulaiure comment step
* lors du clique sur le bouton valider
*/

function submit(value, dispatch, props) {
  if (props.returnReason?.code === 'other' && !props.customReturnReason) {
    throw new SubmissionError({
      customReturnReason: translate('required')
    })
  } else {
    const  { returnReason, desiredItem, customReturnReason, returnSecondaryReason, returnNatureCode, index } = props
    const actionItem = {
      returnReason,
      desiredItem,
      customReturnReason,
      returnSecondaryReason,
      returnNatureCode,
      index
    }
    // clear if not needed
    if(!(returnReason?.code_loki === 'TOO_LARGE' || returnReason?.code_loki === 'TOO_SMALL')) {
      actionItem.returnSecondaryReason = []
    }
    console.log(actionItem)
    dispatch(setActionItem(actionItem))
    dispatch(closeReturnModal())
      setTimeout(() => {
        dispatch(resetSwipeableIndex())
        dispatch(expandCard())
    }, 400);
  }
}

export default submit
