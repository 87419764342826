const stage = {
  url: {
    URL_SYMPL: 'https://stage.sympl.fr',
    URL_STOCK: 'https://stock-stage.sympl.fr'
  },
  token: 'c789e48bbbc79f5274f8da6de618ec9a5b46759dfd8e2a27d634d048688d7db6',
};

const prod = {
  url: {
    URL_SYMPL: 'https://live.sympl.fr',
    URL_STOCK: 'https://stock.sympl.fr'
  },
  token: '8c727c1f1adb8241cd3cb70865a3b0db3f63a9014b3cd807e74d4d9479fb3abd',
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : stage;

export default config;
