import { Button, styled } from "@material-ui/core";

const AsphalteButton = styled(Button) ({
    backgroundColor: '#002E5D',
    borderRadius: 0,
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#002E5D',
      opacity: 0.8
    },
    fontWeight: 600
});

export default AsphalteButton