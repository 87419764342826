import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import trad from "../../../i18n/messages/index";

import RenderTextField from '../../utilis/renderField/RenderTextField'
import RenderSelectField from '../../utilis/renderField/RenderSelectField'

import submitExchangeAdressForm from './submitExchangeAdressForm'

import countryList from '../../returnAdress/countryList.json';

import translate from '../../../i18n/translate';


class ExchangeAdress extends Component {
  componentDidMount() {
    const { prefilled, recipientAddress } = this.props
    prefilled('street', recipientAddress.street)
    prefilled('city', recipientAddress.city)
    prefilled('postalCode', recipientAddress.postalCode)
    prefilled('countryCode', recipientAddress.countryCode)
  }

  render() {
    const { handleSubmit, intl } = this.props;
    const country = trad[intl.locale]['country'];
      return (
        <>
          <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>
            {translate("return_place")}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit} style={{display: 'flex'}}>
              <Grid container direction="row" spacing={3} justifyContent="center">
                  <Grid item xs={10}>
                    <Field id="street"
                           name="street"
                           label={translate("address")}
                           component={RenderTextField}
                           fullWidth
                           type="text"/>
                  </Grid>


                  <Grid item xs={5}>
                    <Field id="city"
                           name="city"
                           label={translate("city")}
                           component={RenderTextField}
                           fullWidth
                           type="text"/>
                  </Grid>
                  <Grid item xs={5}>
                    <Field id="postal-code"
                           name="postalCode"
                           label={translate("postal_code")}
                           component={RenderTextField}
                           fullWidth
                           type="text"/>
                  </Grid>


                  <Grid item xs={10}>
                    <Field  id="country-code"
                            name="countryCode"
                            placeHolder={country}
                            dataCy="adress"
                            fullWidth
                            component={RenderSelectField}
                            items={countryList}/>
                  </Grid>
                </Grid>
            </form>
          </DialogContent>
        </>
      )
    }
  }

  const mapStateToProps = state => ({
    recipientCriteria : state.login.recipientCriteria,
    recipientAddress: state.returnItem.currentUserInformations.recipientAddress
  })

  const mapDispatchToProps = dispatch => ({
    prefilled: (field, value) =>
      dispatch(change('exchangeAdress', field, value))
  })

  ExchangeAdress = reduxForm({
    form: 'exchangeAdress',
    onSubmit: submitExchangeAdressForm
  })(ExchangeAdress)

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExchangeAdress));
