import React, { Component } from "react";
import styled from "styled-components";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import ConfirmCancelDialog from "./ConfirmCancelDialog";

import { connect } from "react-redux";
import AsphalteButton from "../utilis/AsphalteButton";

const Title = styled.h2`
  font-weight: 300;
  font-size: 1.2em;
  text-align: center;
`;

const ReturnFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const Adress = styled.span`
  font-weight: bold;
`;

/**
 * @description Composant informant l'utilisateur qu'il à déjà généré une étiquette de retour
 */

class AlreadyRequestCourier extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { exchangeInformations } = this.props;
    const { open } = this.state;
    const {
      country,
      street,
      street2,
      postalCode,
      city,
      state,
      complement
    } = exchangeInformations.pickupAddress;
    return (
      <div>
        <ConfirmCancelDialog isOpen={open} handleClose={this.handleClose} />
        <ReturnFormContainer>
          <Paper
            className="white-box"
            elevation={0}
            style={{ padding: "20px 30px 20px 30px" }}
          >
            <Grid container item spacing={0} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Title>Vous avez déjà commandé un coursier</Title>
                <div style={{ textAlign: "center" }}>
                  <p>
                    Il va se présenter le{" "}
                    {moment(
                      exchangeInformations.bookingTimeStart,
                      "YYYY-MM-DD"
                    ).format("dddd D MMMM YYYY")}{" "}
                    entre{" "}
                    {moment(exchangeInformations.bookingTimeStart).format(
                      "H:mm"
                    )}{" "}
                    et{" "}
                    {moment(exchangeInformations.bookingTimeEnd).format("H:mm")}{" "}
                    à l'adresse suivante :
                  </p>
                  <div>
                    <Adress>
                      {" "}
                      {street} {street2} {complement} {postalCode}, {city},{" "}
                      {country} {state}{" "}
                    </Adress>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <AsphalteButton
                variant="outlined"
                id="cancel-courier-button"
                className="btn-black"
                style={{ marginTop: 30 }}
                onClick={this.handleClickOpen}
              >
                Annuler ma demande
              </AsphalteButton>
            </Grid>
          </Paper>
        </ReturnFormContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    exchangeInformations: state.returnItem.exchangeInformations
  };
};

export default connect(mapStateToProps)(AlreadyRequestCourier);
