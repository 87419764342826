import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import AsphalteButton from "../utilis/AsphalteButton";

class notFound extends Component {
  render() {
    return (
      <div style={{ paddingBottom: "40px" }}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ fontSize: "2.4em", marginTop: "5%" }}
        >
          <h1>404 - Page non trouvée</h1>
          <p>{"Cette page n'est pas disponible"}</p>
          <div>
            <AsphalteButton
              variant="contained"
              className="btn-black"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              {"Retour à l'accueil"}
            </AsphalteButton>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(notFound);
