import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector } from "redux-form";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import styled from "styled-components";
import moment from "moment";
import "moment/locale/fr";

import { setbookingTimeToken } from "./actions";
import AsphalteButton from "../../utilis/AsphalteButton";

moment.locale("fr");

const DayNumber = styled.span`
  margin: 0.5em;
  font-size: 1.2em;
  font-weight: 400;
  cursor: pointer;
  padding: 0.5em;
  ${({ active }) =>
    active &&
    `
    background-color: #002E5D;
    color: #FFFFFF;
    font-weight: 600;
  `}
`;

const SelectedDate = styled.div`
  margin-top: 1em;
  font-size: 1.5em;
  font-weight: 300;
`;

const Container = styled.div`
  margin-top: 1em;
`;

const SelectTimeSlot = styled.div`
  margin: 1em;
  display: inline-block;
  cursor: pointer;
`;

const DialogTitleCustom = styled(DialogTitle)`
  text-align: center;
  font-weight: 300;
`;

class SelectBookingDate extends Component {
  state = {
    index: 0
  };
  render() {
    const {
      setbookingTimeToken,
      handleSwipeableIndex,
      availableBookingDates
    } = this.props;
    return (
      <div>
        <DialogTitleCustom id="alert-dialog-slide-title">
          Choisissez l'heure et le jour du rendez-vous
        </DialogTitleCustom>
        <DialogContent style={{ textAlign: "center" }}>
          {availableBookingDates.map((availableBookingDate, index) => {
            return (
              <div key={index} style={{ display: "inline-block" }}>
                <DayNumber
                  onClick={() => this.setState({ index: index })}
                  active={this.state.index === index}
                  id={`day-number-${index}`}
                >
                  {moment(availableBookingDate.date, "YYYY-MM-DD").format("D")}
                </DayNumber>
              </div>
            );
          })}
          <Container>
            <SelectedDate id="selected-date">
              {moment(
                availableBookingDates[this.state.index].date,
                "YYYY-MM-DD"
              ).format("dddd D MMMM YYYY")}
            </SelectedDate>
          </Container>
          <Container>
            {availableBookingDates[this.state.index].timeSlots.map(
              (timeSlot, index) => {
                return (
                  <SelectTimeSlot
                    key={index}
                    onClick={() => {
                      setbookingTimeToken(timeSlot.token);
                      handleSwipeableIndex(1);
                    }}
                  >
                    <AsphalteButton
                      variant="contained"
                      id={`time-slot-${index}`}
                    >
                      {`${moment(timeSlot.start).format("H:mm")} - ${moment(
                        timeSlot.end
                      ).format("H:mm")}`}
                    </AsphalteButton>
                  </SelectTimeSlot>
                );
              }
            )}
          </Container>
        </DialogContent>
      </div>
    );
  }
}

const returnAdressSelector = formValueSelector("returnAdress");
const mapStateToProps = state => {
  return {
    postalCode: returnAdressSelector(state, "postalCode"),
    countryCode: returnAdressSelector(state, "countryCode"),
    availableBookingDates: state.returnAdress.availableBookingDates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setbookingTimeToken: token => dispatch(setbookingTimeToken(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectBookingDate));
