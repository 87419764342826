import React, { Component } from "react";
import CardItem from "./CardItem";
import Grid from "@material-ui/core/Grid";
import Modal from "../modal/Modal";
import {
  setSeletecItem,
  resetSwipeableIndex,
  openReturnModal,
  setSwitchableProduct,
  openConfirmationCarrierModal,
  openConfirmationCourierModal,
  openConfirmationPickupModal
} from "../actions";
import { connect } from "react-redux";
import { getSwitchableProducts } from "../../../api/api";

import translate from '../../../i18n/translate';

import ConfirmationCarrierModal from "../confirmationCarrierModal/Modal";
import ConfirmationCourierModal from "../confirmationCourierModal/Modal";
import AsphalteButton from "../../utilis/AsphalteButton";
import { Container } from "@material-ui/core";

/**
 * Composant principale de la page d'échange
 * ou de retour de produit
 */
class ReturnItems extends Component {
  state = {
    loadingSwitchableProducts: false,
    isDisabled: true
  };

  /**
   * @function openModal
   * @param {object} - item
   * @description ouvre la modal pour généré l'échange
   * et récupére la liste des produit avec lesquels le remplacement
   * est possible
   */
  openModal(item) {
    this.setState({ loadingSwitchableProducts: true });
    getSwitchableProducts({
      barcodeValue: item.ean13,
      companyExternalId: this.props.companyExternalId
    }).then(res => {
      if (res.data.success === true) {
        this.props.setSwitchableProduct(res.data.products);
        this.setState({ loadingSwitchableProducts: false });
      } else {
        this.props.setSwitchableProduct([]);
        this.setState({ loadingSwitchableProducts: false });
      }
    });
    this.props.setSeletecItem(item);
    this.props.openReturnModal();
  }

  isDisabled() {
    return (
      this.props.commandItems.filter(e => e.returnNatureCode !== undefined)
        .length > 0
    );
  }

  render() {
    const {
      commandItems,
      shipmentType,
      openConfirmationCarrierModal,
      openConfirmationCourierModal
    } = this.props;

    return (
      <React.Fragment>
        {shipmentType === "carrier" && (
          <ConfirmationCarrierModal />
        )}
        {shipmentType === "courier" && (
          <ConfirmationCourierModal />
        )}
        <Modal
          loadingSwitchableProducts={this.state.loadingSwitchableProducts}
        />
        <Container
          direction="column"
          maxWidth="lg"
          style={{ marginTop: 16 }}
        >
          <h1 className="page-title long">
            {translate("select_items")}
          </h1>
          <Grid
            id="card-items-container"
            container
            spacing={2}
            justifyContent="center"
          >
            {commandItems.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  md={4}
                  xs={6}
                  onClick={this.openModal.bind(this, item)}
                >
                  <CardItem item={item} id={`card-item-${index}`} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Grid container justifyContent="center" alignItems="center" direction="row">
          <AsphalteButton
            variant="contained"
            className={
              (!this.isDisabled() ? "disabled" : "enabled")
            }
            id="validate-button"
            style={{ marginTop: 40 }}
            disabled={!this.isDisabled()}
            onClick={() => {
              if (shipmentType === "carrier" ) {
                openConfirmationCarrierModal();
              } else {
                openConfirmationCourierModal();
              }
            }}
          >
            {shipmentType === "carrier"
              ? translate("confirm_return")
              : translate("book_courier")}
          </AsphalteButton>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    commandItems: state.returnItem.commandItems,
    companyExternalId: state.returnItem.companyExternalId,
    commandId: state.returnItem.commandId,
    shipmentType: state.returnAdress.shipmentType,
    carrierCode: state.returnAdress.carrierCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSeletecItem: item => dispatch(setSeletecItem(item)),
    resetSwipeableIndex: item => dispatch(resetSwipeableIndex(item)),
    openReturnModal: () => dispatch(openReturnModal()),
    setSwitchableProduct: data => dispatch(setSwitchableProduct(data)),
    openConfirmationCarrierModal: () =>
      dispatch(openConfirmationCarrierModal()),
    openConfirmationCourierModal: () => dispatch(openConfirmationCourierModal()),
    openConfirmationPickupModal: () => dispatch(openConfirmationPickupModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnItems);
