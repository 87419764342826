import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import RenderTextField from "../utilis/renderField/RenderTextField";
import RenderSelectField from "../utilis/renderField/RenderSelectField";

import validate from "./validate";
import countryList from "./countryList.json";
import { getBookingDates } from "../../api/api";
import Modal from "./Modal";

import { setAvailableBookingDates, setCarrierCode, setShipmentType } from "./actions";

import errorCatcher from "../utilis/api/errorCatcher";

import translate from '../../i18n/translate';
import trad from "../../i18n/messages";
import { injectIntl } from 'react-intl';
import AsphalteButton from "../utilis/AsphalteButton";

const Title = styled.h2`
  font-weight: 300;
  font-size: 1.2em;
  text-align: center;
`;

const ReturnFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
`;

/**
 * Composant contenant le fomulaire permettant à l'utilisateur de
 * choisir son adresse pour le retour ou l'échange
 */

class ReturnAdress extends Component {
  state = {
    fetching: false,
    openModal: false
  };

  componentDidMount() {
    const { prefilled, recipientAddress } = this.props;
    prefilled("street", recipientAddress.street);
    prefilled("city", recipientAddress.city);
    prefilled("postalCode", recipientAddress.postalCode);
    prefilled("countryCode", recipientAddress.countryCode);
  }

  submit(values) {
    const {setCarrierCode, setShipmentType} = this.props
    const { postalCode, countryCode } = values;
    getBookingDates({ postalCode, countryCode }).then(
      res => {
        if (res.data.success === true) {
          this.props.setAvailableBookingDates(res.data.availableBookingDates);
        } else {
          this.props.setAvailableBookingDates([]);
        }

        if (res.data.success === true || countryCode === "FR") {
          this.setState({ openModal: true });
        } else {
          setCarrierCode(null)
          setShipmentType("carrier")
          this.props.history.push("/return-items");
        }
      },
      error => {
        errorCatcher(error.response.status, this.props.history);
      }
    );
  }

  render() {
    const { handleSubmit, intl } = this.props;
    const country = trad[intl.locale]['country'];
    return (
      <div>
        <Modal open={this.state.openModal} handleClose={() => { this.setState({ openModal: false })}}/>
        <ReturnFormContainer>
          <Paper className="white-box" elevation={0}>
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <Grid container spacing={0} direction="column">
                <Grid container item spacing={0} justifyContent="center">
                  <Grid item xs={10} md={8}>
                    <Title>
                      {translate("from_which_address")}
                    </Title>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} direction="column">
                <Grid container item spacing={0} justifyContent="center">
                  <Grid item xs={10} md={8}>
                    <Field
                      id="street"
                      style={{ width: "95%" }}
                      name="street"
                      label={translate("address")}
                      component={RenderTextField}
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={0} justifyContent="center">
                  <Grid item xs={10} md={4}>
                    <Field
                      id="city"
                      style={{ width: "95%" }}
                      name="city"
                      label={translate("city")}
                      component={RenderTextField}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={10} md={4}>
                    <Field
                      id="postal-code"
                      style={{ width: "95%" }}
                      name="postalCode"
                      label={translate("postal_code")}
                      component={RenderTextField}
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={0} justifyContent="center">
                  <Grid item xs={10} md={8}>
                    <Field
                      id="country-code"
                      style={{ width: "95%" }}
                      name="countryCode"
                      placeHolder={country}
                      dataCy="adress"
                      component={RenderSelectField}
                      items={countryList}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
              >
                <AsphalteButton
                  variant="contained"
                  type="submit"
                  id="button-validate-adress"
                >
                  {translate("next")}
                </AsphalteButton>
              </div>
            </form>
          </Paper>
        </ReturnFormContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  prefilled: (field, value) => dispatch(change("returnAdress", field, value)),
  setAvailableBookingDates: bookingDate =>
    dispatch(setAvailableBookingDates(bookingDate)),
  setShipmentType: shipmentType => dispatch(setShipmentType(shipmentType)),
  setCarrierCode: carrierCode => dispatch(setCarrierCode(carrierCode))
});

const mapStateToProps = state => ({
  recipientAddress: state.returnItem.currentUserInformations.recipientAddress
});

ReturnAdress = reduxForm({
  form: "returnAdress",
  destroyOnUnmount: false,
  validate
})(ReturnAdress);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ReturnAdress)));
