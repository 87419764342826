export const RESET_STATE = 'RESET_STATE'
export const resetState = () => ({type: RESET_STATE})

export const ADD_CURRENT_ITEMS = 'ADD_CURRENT_ITEMS'
export const addCurrentItems = (id, items) => ({type: ADD_CURRENT_ITEMS, id, items})

export const LOADING_CREATE_RETURN = 'LOADING_CREATE_RETURN'
export const loadingCreateReturn = (data) => ({type: LOADING_CREATE_RETURN, data})

export const SUCCESS_CREATE_RETURN = 'SUCCESS_CREATE_RETURN'
export const successCreateReturn = () => ({type: SUCCESS_CREATE_RETURN})

export const ADD_CURRENT_USER_INFORMATIONS = 'ADD_CURRENT_USER_INFORMATIONS'
export const addCurrentUserInformation = (currentUserInformations) => ({type: 'ADD_CURRENT_USER_INFORMATIONS', currentUserInformations})

// Carrier modal
export const OPEN_CONFIRMATION_CARRIER_MODAL = 'OPEN_CONFIRMATION_CARRIER_MODAL'
export const openConfirmationCarrierModal = () => ({type: OPEN_CONFIRMATION_CARRIER_MODAL})

export const CLOSE_CONFIRMATION_CARRIER_MODAL = 'CLOSE_CONFIRMATION_CARRIER_MODAL'
export const closeConfirmationCarrierModal = () => ({type: CLOSE_CONFIRMATION_CARRIER_MODAL})

// Pickup modal
export const OPEN_CONFIRMATION_PICKUP_MODAL = 'OPEN_CONFIRMATION_PICKUP_MODAL'
export const openConfirmationPickupModal = () => ({type: OPEN_CONFIRMATION_PICKUP_MODAL})

export const CLOSE_CONFIRMATION_PICKUP_MODAL = 'CLOSE_CONFIRMATION_PICKUP_MODAL'
export const closeConfirmationPickupModal = () => ({type: CLOSE_CONFIRMATION_PICKUP_MODAL})

// Courier modal
export const OPEN_CONFIRMATION_COURIER_MODAL = 'OPEN_CONFIRMATION_COURIER_MODAL'
export const openConfirmationCourierModal = () => ({ type: OPEN_CONFIRMATION_COURIER_MODAL })

export const CLOSE_CONFIRMATION_COURIER_MODAL = 'CLOSE_CONFIRMATION_COURIER_MODAL'
export const closeConfirmationCourierModal = () => ({type: CLOSE_CONFIRMATION_COURIER_MODAL})

export const ADD_EXCHANGE_INFORMATIONS = 'ADD_EXCHANGE_INFORMATIONS'
export const addExchangeInformations = (exchangeInformations) => ({type: 'ADD_EXCHANGE_INFORMATIONS', exchangeInformations})

export const SET_RETURN_SLIP_URL = 'SET_RETURN_SLIP_URL'
export const addReturnSlipUrl = (returnLabelUrl) => ({type: SET_RETURN_SLIP_URL, returnLabelUrl})

export const SET_DESIRED_ITEM = 'SET_DESIRED_ITEM'
export const setDesiredItem = (desiredItem) => ({type: SET_DESIRED_ITEM, desiredItem})

export const SET_EXTERNAL_COMPANY_ID = 'SET_EXTERNAL_COMPANY_ID'
export const setExternalCompanyId = (companyExternalId) => ({type: SET_EXTERNAL_COMPANY_ID, companyExternalId})

export const SET_SWITCHABLE_PRODUCT = 'SET_SWITCHABLE_PRODUCT';
export const setSwitchableProduct = (switchableProducts) => ({type: SET_SWITCHABLE_PRODUCT, switchableProducts})

export const SET_EXPAND_CARD = 'SET_EXPAND_CARD';
export const expandCard = () => ({type: SET_EXPAND_CARD})

export const SET_ACTION_ITEM = 'SET_ACTION_ITEM'
export const setActionItem = (actionItem) => ({type: SET_ACTION_ITEM, actionItem})

export const SET_RETURN_NATURE_CODE = 'SET_RETURN_NATURE_CODE'
export const setReturnNatureCode = (returnNatureCode) => ({type: SET_RETURN_NATURE_CODE, returnNatureCode})

export const OPEN_RETURN_MODAL = 'OPEN_RETURN_MODAL'
export const openReturnModal = () => ({type: OPEN_RETURN_MODAL})

export const CLOSE_RETURN_MODAL = 'CLOSE_RETURN_MODAL'
export const closeReturnModal = () => ({type: CLOSE_RETURN_MODAL})

export const RESET_SWIPEABLE_INDEX = 'RESET_SWIPEABLE_INDEX'
export const resetSwipeableIndex = () => ({type: RESET_SWIPEABLE_INDEX})

export const SET_STEPS_ENUM = 'SET_STEPS_ENUM';
export const setStepsEnum = (stepsEnum) => ({type : SET_STEPS_ENUM, stepsEnum})

export const HANDLE_CHANGE_SWIPEABLE_INDEX = 'HANDLE_CHANGE_SWIPEABLE_INDEX'
export const handleChangeSwipeableIndex = () => ({type: HANDLE_CHANGE_SWIPEABLE_INDEX})

export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
export const setSeletecItem = (selectedItem) => ({type: SET_SELECTED_ITEM, selectedItem})

export const ADD_USER_COMMANDS = 'ADD_USER_COMMANDS'
export const addUserCommands = (userCommands) => ({type: 'ADD_USER_COMMANDS', userCommands})
