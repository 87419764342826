import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Zoom from "@material-ui/core/Zoom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { reduxForm } from "redux-form";
import { resetSwipeableIndex, closeReturnModal } from "../actions";

// Step
import CauseStep from "./form/causeStep/CauseStep";
import CommentStep from "./form/commentStep/CommentStep";
import ExchangeStep from "./form/exchangeStep/ExchangeStep";
import MainStep from "./form/mainStep/MainStep";

// TODO AJOUTER LES PHOTOS DES VRAI PRODUIT

const styles = {
  media: {
    objectFit: "cover"
  }
};

function Transition(props) {
  const duration = {
    enteringScreen: 250,
    leavingScreen: 200
  };
  return (
    <Zoom
      {...props}
      timeout={{ enter: duration.enteringScreen, exit: duration.leavingScreen }}
    />
  );
}

class FormDialog extends Component {
  render() {
    const {
      openReturnModal,
      closeReturnModal,
      swipeableIndex,
      resetSwipeableIndex,
      loadingSwitchableProducts,
    } = this.props;
    return (
      <div>
        <Dialog
          open={openReturnModal}
          onClose={() => {
            closeReturnModal();
            setTimeout(function() {
              resetSwipeableIndex();
            }, 400);
          }}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent style={{ padding: 0, maxWidth: "500px" }}>
            <div style={{ padding: "10px 20px 10px 20px" }}>
              <SwipeableViews index={swipeableIndex}>
                <div>
                  <MainStep
                    loadingSwitchableProducts={loadingSwitchableProducts}
                  />
                </div>
                <div>
                  <CauseStep />
                </div>
                <div>
                  <ExchangeStep />
                </div>
                <div>
                  <CommentStep />
                </div>
              </SwipeableViews>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    swipeableIndex: state.returnItem.swipeableIndex,
    selectedItem: state.returnItem.selectedItem,
    openReturnModal: state.returnItem.openReturnModal,
    companyExternalId: state.returnItem.companyExternalId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSwipeableIndex: () => dispatch(resetSwipeableIndex()),
    closeReturnModal: () => dispatch(closeReturnModal())
  };
};

FormDialog = reduxForm({
  form: "modal"
})(FormDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormDialog));
