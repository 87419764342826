import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import {
  handleChangeSwipeableIndex,
  setStepsEnum,
  setReturnNatureCode,
  setDesiredItem,
} from "../../../actions";
import { stepsExchange, stepsReturn, stepsIncorectItem } from "./stepsEnum";
import styled from "styled-components";
import translate from "../../../../../i18n/translate";
import AsphalteButton from "../../../../utilis/AsphalteButton";

let NoSwichableProductAvailable = styled.div`
  width: 60%;
  text-align: center;
  margin-top: 40px;
  border: 1px black solid;
  padding: 3px;
  margin-top: 40px;
  border-radius: 4px;
`;

const MainStep = ({
  selectedItem,
  handleChangeSwipeableIndex,
  setStepsEnum,
  setReturnNatureCode,
  loadingSwitchableProducts,
  switchableProducts,
  setDesiredItem,
}) => {
  const renderButtonGroup = () => {
    if (loadingSwitchableProducts) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ marginTop: "10%" }}
        >
          <CircularProgress />
        </Grid>
      );
    } else {
      return (
        <Grid container justifyContent="center" alignItems="center" direction="column">
          {switchableProducts.length > 0 ? (
            <AsphalteButton
              id="exchange-button"
              type="submit"
              variant="contained"
              style={{ marginTop: 40, width: "60%" }}
              onClick={() => {
                setStepsEnum(stepsExchange);
                setReturnNatureCode("exchange");
                handleChangeSwipeableIndex();
              }}
            >
              {translate('exchange')}
            </AsphalteButton>
          ) : (
            <NoSwichableProductAvailable id="not-possible-exchange-button">
              {translate('exchange_not_possible')}
            </NoSwichableProductAvailable>
          )}
          <AsphalteButton
            id="return-button"
            variant="contained"
            style={{ marginTop: 40, width: "60%" }}
            onClick={() => {
              setStepsEnum(stepsReturn);
              setReturnNatureCode("return");
              handleChangeSwipeableIndex();
            }}
          >
            {translate('refund')}
          </AsphalteButton>
          <AsphalteButton
            id="not-received-button"
            variant="contained"
            style={{ marginTop: 40, marginBottom: 40, width: "60%" }}
            onClick={() => {
              setStepsEnum(stepsIncorectItem);
              setReturnNatureCode("exchange");
              setDesiredItem(selectedItem);
              handleChangeSwipeableIndex();
            }}
          >
            {translate('wrong_item')}
          </AsphalteButton>
        </Grid>
      );
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
        <Typography align="center" style={{ fontSize: "1.2em", fontWeight: 400, marginTop: 16 }}>
          {selectedItem.description}
        </Typography>
        {(selectedItem && selectedItem.color && selectedItem.size) && (
          <Typography
            align="center"
            style={{ fontSize: "0.9em", fontWeight: "bold" }}
          >
            {selectedItem.color} / {selectedItem.size}
          </Typography>
        )}
        <Typography
          align="center"
          style={{ fontSize: "0.8em" }}
        >
          ref : {selectedItem.externalReference}
        </Typography>
      {renderButtonGroup()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedItem: state.returnItem.selectedItem,
    switchableProducts: state.returnItem.switchableProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeSwipeableIndex: () => dispatch(handleChangeSwipeableIndex()),
    setStepsEnum: (stepsEnum) => dispatch(setStepsEnum(stepsEnum)),
    setReturnNatureCode: (returnNatureCode) =>
      dispatch(setReturnNatureCode(returnNatureCode)),
    setDesiredItem: (desiredItem) => dispatch(setDesiredItem(desiredItem)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainStep);
