import React from "react";

import translate from '../../../i18n/translate';

import "./buttonLoading.css";
import AsphalteButton from "../../utilis/AsphalteButton";

const ButtonLoading = () => {
  return (
    <AsphalteButton id="loading-button" variant="contained">
      {translate("loading")}
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </AsphalteButton>
  );
};

export default ButtonLoading;
