import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { productTypes, returnReasons, returnSecondaryReasons } from "./renderSelectItems";
import { reduxForm } from "redux-form";
import { handleChangeSwipeableIndex } from "../../../actions";
import { connect } from "react-redux";
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@material-ui/core";
import validate from "./validate";

import translate from '../../../../../i18n/translate';
import { injectIntl } from 'react-intl';
import AsphalteButton from "../../../../utilis/AsphalteButton";
import { useState } from "react";
import { Field } from "redux-form";

/**
 * Composant permettant de selectioner la cause de
 * du retour
 */

const CauseStep = (props) => {

  const { handleSubmit, returnNatureCode, intl, selectedItem } = props;
  const [checkedCode, setCheckedCode] = useState(null)
  const [checkedSecondaryCodes, setCheckedSecondaryCodes] = useState([])
  const [canSubmit, setCanSubmit] = useState(true)
  const [returnReasonsRandomized, setReturnReasonsRandomized] = useState(returnReasons(intl.locale))
  const [returnSecondaryReasonsRandomized, setReturnSecondaryReasonsRandomized] = useState([])

  const getReturnReason = () => {
    return returnReasonsRandomized.find((p) => p.code_loki === checkedCode)
  }

  useEffect(() => {
    setReturnReasonsRandomized(returnReasons(intl.locale).sort(() => Math.random() - 0.5))
  }, [intl.locale])

  useEffect(() => {
    if (checkedCode 
      && selectedItem.shopify.productType 
      && returnSecondaryReasonsRandomized.length === 0 
      && returnSecondaryReasons(checkedCode,productTypes()[selectedItem.shopify.productType], intl.locale).length > 0) {
      setReturnSecondaryReasonsRandomized(returnSecondaryReasons(checkedCode,productTypes()[selectedItem.shopify.productType], intl.locale))
    }
  }, [checkedCode, intl, selectedItem, returnSecondaryReasonsRandomized])

  useEffect(() => {
    if (checkedCode && returnSecondaryReasonsRandomized.length > 0 && checkedSecondaryCodes.length > 0) {
      setCanSubmit(true)
    } else if (checkedCode && returnSecondaryReasonsRandomized.length === 0) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }, [checkedCode, checkedSecondaryCodes, returnSecondaryReasonsRandomized])

  const renderRadioGroup = (props) => {
    const {
      input: { value, onChange},
    } = props

    const handleChange = (event) => {
      setCheckedCode(event.target.value)
      onChange(returnReasons(intl.locale).find((p) => p.code_loki === event.target.value))
    }

    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
        <RadioGroup value={value?.code_loki} onChange={handleChange} >
        {returnReasonsRandomized.map((p) => {
            return (
              <FormControlLabel key={p.code_loki} value={p.code_loki} control={<Radio color="primary" style={{padding: 6, paddingLeft: 10}}/>} label={<Typography
                style={{ fontSize: "0.9em", lineHeight: 1.2 }}
              >{p.name}</Typography>}/>
            )
        })}
        </RadioGroup>
      </Grid>
    )
  }

  const renderSecondaryCheckboxGroup = (props) => {
    const {
      input: { value, onChange},
    } = props

    const handleChange = (event) => {

      const arr = value ? [...value] : [];

      if (event.target.checked) {
        arr.push(returnSecondaryReasonsRandomized.find((r) => r.code_loki === event.target.name))
      } else {
        arr.splice(arr.findIndex((r) => r.code_loki === event.target.name), 1)
      }

      onChange(arr)
      setCheckedSecondaryCodes(arr)
    }

    return (
      <>
      {returnSecondaryReasonsRandomized.length > 0 && (
        <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
          <FormGroup value={value}>
          {returnSecondaryReasonsRandomized.map((p) => {
              return (
                <FormControlLabel key={p.code_loki} control={
                  <Checkbox 
                    name={p.code_loki}
                    inputProps={{ 'aria-label': 'controlled' }} 
                    checked={value && value.findIndex((r) => r.code_loki === p.code_loki) !== -1 ? true : false} 
                    onChange={handleChange} 
                    color="primary" 
                    style={{padding: 6, paddingLeft: 10}}/>} 
                    label={<Typography
                      style={{ fontSize: "0.9em", lineHeight: 1.2 }}
                    >{p.name}</Typography>}
                  />
              )
          })}
          </FormGroup>
        </Grid>
      )}
    </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Typography
            align="center"
            style={{ fontSize: "1em", marginTop: 20,paddingBottom: 16, fontWeight: 'bold' }}
          >
            {returnNatureCode === "exchange"
              ? translate('exchange_reason')
              : translate('return_reason')}
          </Typography>
          { !checkedCode || (checkedCode && returnSecondaryReasonsRandomized.length === 0) ? (
            <Field name="returnReason" component={renderRadioGroup} />
          ) : (
            <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column" style={{flex: 1}}>
              <Typography style={{ fontSize: "1em", fontWeight: 400, paddingBottom: 8 }}>
                {translate('selected_reason')}
                <span style={{ fontSize: "1em", fontWeight: 600 }}> {getReturnReason() && getReturnReason().name}</span>
              </Typography>
              
              <Field name="returnSecondaryReason" component={renderSecondaryCheckboxGroup} />
            </Grid>
          )}
          <Grid container justifyContent="flex-end" alignItems="flex-end" direction="row">
            { returnSecondaryReasonsRandomized.length > 0 && (
              <AsphalteButton
                id="cause-step-button"
                variant="outlined"
                style={{ marginRight: 8 }}
                onClick={() => {
                  setCheckedCode(null)
                  setCheckedSecondaryCodes([])
                  setReturnSecondaryReasonsRandomized([])
                }}
              >
                {translate('return')}
              </AsphalteButton>
            )}
            { canSubmit && (
              <AsphalteButton
                id="cause-step-button"
                type="submit"
                variant="outlined"
                disabled={!canSubmit}
              >
                {translate('submit')}
              </AsphalteButton>
            )}
          </Grid>
        </Grid>
    </form>
  );
}

const mapStateToProps = state => {
  return {
    selectedItem: state.returnItem.selectedItem,
    returnNatureCode: state.returnItem.returnNatureCode
  };
};

const CauseStepForm = reduxForm({
  form: "causeStep",
  onSubmit: (values, dispatch) => {
    dispatch(handleChangeSwipeableIndex());
  },
  validate
})(CauseStep);

export default connect(mapStateToProps)(injectIntl(CauseStepForm));
