import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import mappingErrors from '../utilis/api/mappingErrors';
import { closeSnackbarError } from './actions';


class SnackbarError extends Component {
  render() {
    return (
      <Snackbar
        id="message-id"
        style={{textAlign: 'center'}}
        anchorOrigin={{ vertical: 'top', horizontal:'center' }}
        open={this.props.openSnackBar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={6000}
        onClose={this.props.closeSnackbarError}
        message={
          <span>
          {
            mappingErrors[this.props.errorCode] !== undefined ?
            mappingErrors[this.props.errorCode] :
            "Une erreur inconnue s'est produite"
          }
          </span>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openSnackBar: state.snackbarError.openSnackbar,
    errorCode: state.snackbarError.errorCode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeSnackbarError: () => {
      dispatch(closeSnackbarError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarError);
