import React, { Component } from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AsphalteButton from "../utilis/AsphalteButton";
import { connect } from "react-redux";

import translate from '../../i18n/translate';

const Title = styled.h2`
  font-weight: 300;
  font-size: 1.2em;
  text-align: center;
`;

const ReturnFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;
/**
 * @description Composant informant l'utilisateur qu'il à déjà généré une étiquette de retour
 */

class AlreadyGenerateReturnLabel extends Component {
  render() {
    const { returnLabelUrl } = this.props;
    return (
      <div>
        <ReturnFormContainer>
          <Paper
            className="white-box"
            elevation={0}
            style={{ padding: "20px 30px 20px 30px" }}
          >
            <Grid container item spacing={0} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Title>{translate('already_generated_returns')}</Title>
                <AsphalteButton
                  type="submit"
                  variant="contained"
                  style={{ marginTop: 40, marginBottom: 20 }}
                  onClick={() => {
                    window.location.replace(returnLabelUrl);
                  }}
                >
                  {translate('download_returns')}
                </AsphalteButton>
              </Grid>
            </Grid>
          </Paper>
        </ReturnFormContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    returnLabelUrl: state.returnItem.returnLabelUrl
  };
};

export default connect(mapStateToProps)(AlreadyGenerateReturnLabel);
