import axios from "axios";
/*
interface IRetour {
  ean: number;
  sku: string;
  orderId: number;
  orderName?: string;
  comment?: string;
  raisonPrincipale: string;
  raisonsSecondaire?: Array<string>;
}*/

class LokiService {

  constructor() {
    if (!process.env.REACT_APP_LOKI_URL) {
      throw new Error("Missing env var LOKI_URL");
    }

    this.baseUrl = process.env.REACT_APP_LOKI_URL;
    this.headers = {
      Accept: "application/json",
      'Content-Type': 'application/json',
    };
  }

  createRetour(retour){
    return axios.post(`${this.baseUrl}/retours`, JSON.stringify(retour), {
      headers: { ...this.headers },
    });
  }
}

const service = new LokiService();

export default service;
