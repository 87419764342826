import React from 'react'
import TextField from '@material-ui/core/TextField'

/**
* Composant création et configuration de tous les input text
* utilisé par react-form
*/

const RenderTextField = ({ input, label, meta, helperText, style, placeholder, multiline, variant, rows, id, type, pattern, fullWidth}) => {
  let displayError = meta.submitFailed && meta.error ? true : false;
  return (
    <TextField
      style={style}
      label={label}
      placeholder={placeholder}
      multiline={multiline}
      minRows={rows}
      variant={variant}
      helperText={displayError ? meta.error : helperText}
      error={displayError}
      type={type}
      id={id}
      inputProps={{pattern, 'data-hj-whitelist': true}}
      fullWidth={fullWidth}
      {...input}
    />)
}

export default RenderTextField;
