import { SubmissionError } from 'redux-form'
import createReturnByCourier from '../../utilis/api/createReturnByCourier';
import validateEmail from '../../utilis/validateEmail';

import translate from '../../../i18n/translate';

const submit = (values, dispatch, props) => {
  const { handleSwipeableIndex } = props

  if (!validateEmail(values.contactEmail)) {
    throw new SubmissionError({
      contactEmail : translate("invalid_email")
    })
  }
  if (!values.contactEmail) {
    throw new SubmissionError({
      contactEmail : translate("required")
    })
  }
  if (!values.contactPhoneNumber) {
    throw new SubmissionError({
      contactPhoneNumber : translate("required")
    })
  }
  if (!values.contactFirstname) {
    throw new SubmissionError({
      contactFirstname : translate("required")
    })
  }
  if (!values.contactLastname) {
    throw new SubmissionError({
      contactLastname : translate("required")
    })
  }
  createReturnByCourier()
  handleSwipeableIndex(2)
}

export default submit
