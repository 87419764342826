import React, { Component } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

import translate from '../../../i18n/translate';

/**
 * @description Page de Confirmation pour les envois postaux
 */
class carrierConfirmation extends Component {
  render() {
    return (
      <div>
        {this.props.loadingCreateReturn ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "11%" }}
          >
            <CircularProgress id="circle-loader" />
          </Grid>
        ) : (
          <div>
            <DialogTitle style={{ textAlign: "center" }}>
              {this.props.successCreateReturn ? (
                <span id="success-create-return-label">
                  {translate("request_received")}
                </span>
              ) : (
                <span id="error-create-return-label">
                  Erreur lors de la génération de votre bon de retour
                </span>
              )}
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText id="alert-dialog-slide-description">
                {this.props.successCreateReturn ? (
                  <span>
                    Vos documents de retours sont en pièce-jointe de l'email qui
                    vient d'être envoyé. Il ne vous reste plus qu'à les
                    imprimer.
                  </span>
                ) : (
                  <span>
                    Notre transporteur rencontre quelques difficultés pour la
                    génération de vos documents de retour, nous vous invitons à
                    réessayer dans quelques instants. Si le problème persiste,
                    n'hésitez pas à nous envoyer un email à
                    help@asphalte.com
                  </span>
                )}
              </DialogContentText>
            </DialogContent>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    successCreateReturn: state.returnItem.successCreateReturn,
    loadingCreateReturn: state.returnItem.loadingCreateReturn,
    shipmentType: state.returnAdress.shipmentType
  };
};

export default connect(mapStateToProps)(carrierConfirmation);
