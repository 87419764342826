import store from "../../../store";
import LokiService from '../../../api/loki'

/*
 * Push return's data to Loki from the returnVoucher of the sympl command and the returnItem of the store
 */
const createLokiReturn = (symplData) => {

    let s = store.getState();

    for(const item of symplData?.returnVoucher?.items) {
  
        let storedItem = s.returnItem.commandItems.find((i) => i.externalReference && (i.externalReference === item.returnedItem?.externalReference))
        if(!storedItem) {
            continue
        }

        const retour = {
            "ean": item.returnedItem.ean13,
            "sku": item.returnedItem.externalReference,
            "comment": item.customReturnReason,
            "orderName": symplData.returnVoucher.command.externalReference,
            "raisonPrincipale": storedItem.returnReason.code_loki,
            "type": item.returnNatureCode.toUpperCase(),
            "size": storedItem.shopify?.size || undefined,
            "color": storedItem.shopify?.color || undefined,
            "productId": storedItem.shopify?.productId || undefined,
            "productName": storedItem.shopify?.productTitle || undefined, // TODO: get it from metafield
            "productVersion": storedItem.shopify?.productVersion || undefined,
            "variantId": storedItem.shopify?.variantId || undefined,
            "returnVoucherId": symplData.returnVoucher.id,
        }

        if (symplData.returnVoucher.returnCommand?.finalCarrier?.code) {
            retour['carrier'] = symplData.returnVoucher.returnCommand?.finalCarrier?.code
        } else if (symplData.returnVoucher?.shipmentType === 'courier') {
            retour['carrier'] = "COURSIER"
        } else {
            retour['carrier'] = undefined
        }

        if (storedItem.returnSecondaryReason && storedItem.returnSecondaryReason.length > 0) {
            retour['raisonsSecondaire'] = storedItem.returnSecondaryReason.map((r) => r.code_loki)
        }

        if (storedItem.shopify?.option3) {
            retour['option3'] = storedItem.shopify?.option3
        }
        
        try {
            LokiService.createRetour(retour).then(res => {
            }, err => {
            console.log(err);
            })
        } catch (e) {
            console.log(e);
        }
    }
}

export default createLokiReturn;