import React, { Component } from "react";
import { Route, Switch, withRouter} from "react-router-dom";
import ReactGA from "react-ga";

import styled from "styled-components";

import Login from "./component/auth/Login";
import PrivateRoute from "./component/auth/PrivateRoute";
import ReturnAdress from "./component/returnAdress/ReturnAdress";
import ReturnItems from "./component/returnItems/home/ReturnItems";
import AlreadyGenerateReturnLabel from "./component/alreadyGenerateReturnLabel/AlreadyGenerateReturnLabel";
import AlreadyRequestCourier from "./component/alreadyRequestCourier/AlreadyRequestCourier";
import notFound from "./component/errorPage/notFound";
import internalError from "./component/errorPage/internalError";

import SnackbarError from "./component/snackbarError/SnackbarError";
import ErrorBoundary from "./ErrorBoundary";

import asphalteHeaderLogo from "./img/logo.svg";

import ReactFlagsSelect from 'react-flags-select';

import { I18nProvider, LOCALES } from './i18n';
import translate from './i18n/translate';
import AsphalteButton from "./component/utilis/AsphalteButton";

if (process.env.REACT_APP_STAGE === "prod") {
  ReactGA.initialize("UA-130132855-1");
  ReactGA.initialize("UA-130036507-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const Header = styled.header`
  min-height: 70px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
`;

const LogoutButtonContainer = styled.div``;

/**
 * Composant principale gestion des routes de l'application
 * avec react-router-dom
 */

class App extends Component {
  state = {
    isAuthenticated: false, locale: LOCALES.FRENCH
  };

  handleAuthentificated() {
    this.setState({ isAuthenticated: true });
  }

  onSelectFlag(countryCode) {
    if (countryCode === "FR") {
      this.setState({locale: LOCALES.FRENCH})
    }
    if (countryCode === "GB") {
      this.setState({locale: LOCALES.ENGLISH})
    }
    if (countryCode === "DE") {
      this.setState({locale: LOCALES.DEUTCH})
    }
  }

  render() {
    const { isAuthenticated, locale } = this.state;
    return (
      <I18nProvider locale={locale}>
        <ErrorBoundary>
          <SnackbarError />
          <Header>
            <div className="header-wrapper">
              <div>
                <a href="https://www.asphalte.com">
                  <img src={asphalteHeaderLogo} alt="Asphalte" height="40" />
                </a>
              </div>
              <div className="header-right">
                <LogoutButtonContainer>
                  {isAuthenticated === true ? (
                    <div>
                      <AsphalteButton
                        variant="outlined"
                        onClick={() => window.location.reload()}
                      >
                        {translate("logout")}
                      </AsphalteButton>

                    </div>
                  ) : (
                    ""
                  )}
                </LogoutButtonContainer>
                <ReactFlagsSelect
                  className="menu-flags"
                  defaultCountry="FR"
                  countries={["GB", "FR", "DE"]}
                  customLabels={{"GB": "EN","FR": "FR", "DE" : "DE"}}
                  onSelect={this.onSelectFlag.bind(this)}
                />
              </div>
            </div>
          </Header>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Login
                  handleAuthentificated={this.handleAuthentificated.bind(this)}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/internal-error"
              component={internalError}
            />
            <PrivateRoute
              path="/already-generate-return-label"
              component={AlreadyGenerateReturnLabel}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              path="/already-request-courier"
              component={AlreadyRequestCourier}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              path="/return-adress"
              component={ReturnAdress}
              isAuthenticated={isAuthenticated}
            />
            <PrivateRoute
              path="/return-items"
              component={ReturnItems}
              isAuthenticated={isAuthenticated}
            />
            <Route component={notFound} />
          </Switch>
        </ErrorBoundary>
      </I18nProvider>
    );
  }
}

export default withRouter((App));
