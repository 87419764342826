import { LOCALES } from '../locales'

const traductions = {
    [LOCALES.DEUTCH]: {
        "home_title": "Umtausch / Rücksendung",
        "home_1": "Schade, das Produkt passt leider nicht. Kein Problem, du hast 14 Tage Zeit, den/die Artikel umzutauschen (solange wir die richtige Größe vorrätig haben, also warte nicht zu lange) oder den Kaufpreis erstattet zu bekommen.",
        "home_2": "Regeln für Rücksendungen:",
        "home_3": "Grundsätzlich können nur Artikel zurückgegeben werden, die sich in demselben Zustand befinden, in dem sie geliefert wurden. Das heißt, dass die Artikel keine Gebrauchsspuren aufweisen, nicht beschädigt, in irgendeiner Weise verändert und auch nicht gewaschen sein dürfen. Also sei vorsichtig, wenn du die Sachen anprobierst. Außerdem solltest du die Artikel in der Originalverpackung mit dem Rücksendeschein, allen Etiketten und auch der Kraftpapierverpackung zurückschicken. Wenn du gut mit den Produkten umgehst, können wir sie an jemand anderen weitergeben. Dann gibt es keine Abfälle und alle sind zufrieden.",
        "home_4": " ",
        "home_4_1": " ",
        "home_4_2": " ",
        "login": "Deine Kontaktdaten",
        "phone": "Telefonnummer",
        "email_phone": "E-Mail/Telefon",
        "order_number": "Bestellnummer",
        "order": "Bestellung",
        "login_comment": "*Wenn du Artikel aus derselben Bestellung ein zweites Mal zurücksendest, schreibe bitte RR vor die Bestellnummer.",
        "submit": "Bestätigen",
        "logout": "Logout",
        "return": "Zurück",
        "cancel": "Abbrechen",
        "next": "Als Nächstes",
        "return_login": "Zurück zum Login",
        "select_order": "Wähle eine Bestellung aus",
        "required": "Pflichtfeld",
        "loading": "Lädt",
        "invalid_email": "Ungültige E-Mail Adresse",
        "email_input": "Gib deine E-Mail-Adresse ein, um eine Bestätigungsnachricht zu erhalten",
        "already_generated_returns": "Du hast deine Rücksendeunterlagen bereits angefordert",
        "download_returns": "Rücksendeunterlagen herunterladen",
        "request_received": "Wir haben deine Anfrage erhalten",
        "booking_courier_error": "Es gab einen Fehler bei der Buchung deiner Rücksendung",
        "booking_courier_confirm": "Du hast soeben eine Bestätigung für deine Rücksendung erhalten",
        "booking_courier_issues": "Wir haben derzeit Probleme mit der Buchung deiner Rücksendung. Bitte versuche es in ein paar Minuten noch einmal. Sollte das Problem weiterhin bestehen, schick uns bitte eine E-Mail an support@asphalte-paris.com",
        "try_again": "Nochmal versuchen",
        "return_place": "Wohin sollen wir deine umgetauschten Artikel schicken?",
        "address": "Adresse",
        "city": "Stadt",
        "postal_code": "Postleitzahl",
        "country": "Land",
        "item_exchange_by": "Dein Artikel wird umgetauscht mit",
        "item_returned": "Dein Artikel wird zurückgeschickt",
        "item_not_received": "Du hast deine Sendung nicht erhalten",
        "select_items": "Wähle die Artikel deiner Bestellung aus, die du zurückgeben möchtest.",
        "confirm_return": "Rückgabe bestätigen",
        "book_courier": "Rücksendung buchen",
        "exchange_reason": "Was ist der Grund für den Umtausch?",
        "return_reason": "Was ist der Grund für die Rücksendung?",
        "selected_reason": "Ausgewählter Grund:",
        "too_big": "Zu groß",
        "too_small": "Zu klein",
        "color_is_not_suitable": "Die Farbe gefällt mir nicht",
        "cut_is_not_suitable": "Die Passform ist nichts für mich",
        "material_is_not_suitable": "Das Material gefällt mir nicht",
        "ordered_two_different_sizes": "Ich habe 2 verschiedene Größen bestellt",
        "ordered_two_different_colors": "Ich habe 2 verschiedene Farben bestellt",
        "damaged_item": "Der Artikel ist beschädigt",
        "changed_mind": "In der Zwischenzeit habe ich ein ähnliches Produkt gefunden",
        "too_late": "Das Produkt kam zu spät in der Saison",
        "add_comment": "Möchtest du einen Kommentar hinzufügen?",
        "exchange_item": "Gegen welchen Artikel möchtest du diesen umtauschen?",
        "available_15days": "Innerhalb von 15 Tagen verfügbar",
        "exchange": "Umtausch",
        "exchange_not_possible": "Du kannst diesen Artikel nicht umtauschen",
        "refund": "Rückzahlung",
        "wrong_item": "Mir wurde der falsche Artikel geliefert",
        "no_order_to_return": "Auf deinem Konto befindet sich momentan keine Bestellung, die zurückgegeben werden kann",
        "order_not_found": "Die Bestellung wurde nicht gefunden",
        "order_cannot_be_returned": "Es sieht so aus, als ob deine Bestellung nicht zurückgegeben werden kann",
        "country_not_available": "Für eine Bestellung aus diesem Land kann kein Rücksendeetikett erstellt werden",
        "problem_creating_return": "Es scheint ein Problem bei der Erstellung des Rücksendeetiketts zu geben, bitte versuche es in ein paar Sekunden erneut",
        "from_which_address": "Von welcher Adresse aus möchtest du deine Artikel zurückschicken?",
        "confirm_cancel_courier": "Bist du sicher, dass du die Rücksendung stornieren möchtest?",
        "courier_cancelled": "Deine Rücksendung wurde storniert",
        "courier_cancelled_error": "Fehler beim Stornieren deines Rücksendeetiketts",
        "send_error_1": "Bitte kontaktiere uns unter",
        "send_error_2": "mit Verweis auf den Fehlercode",
        "send_error_3": "und die ID",
        "carrier_choice": "Wählen Sie Ihr Transportunternehmen",
        "pickup_on_map": "Auf einer Karte anzeigen",
        // Haut 
        "col": "Kragen",
        "epaules": "Schultern",
        "poitrine": "Brust",
        "aisselles": "Achseln",
        "ventre": "Bauch",
        "longueur_manches": "Ärmellänge",
        "largueur_manches": "Weite der Ärmel",
        // Bas
        "taille": "Taille",
        "fourche": "Hintern",
        "hanches": "Hüften",
        "cuisses": "Oberschenkel",
        "mollets": "Waden",
        "chevilles": "Knöchel",
        // Chaussures
        "talon": "Ferse",
        "orteils": "Zehen",
        "longueur_totale": "Gesamtlänge",
        "globalement_trop_large": "Insgesamt zu locker",
        "globalement_trop_serre": "Insgesamt zu eng",
    }
}

export default traductions