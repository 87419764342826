import React, {Component}  from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, change } from 'redux-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import RenderTextField from '../../utilis/renderField/RenderTextField'
import submit from './submit'

import translate from '../../../i18n/translate';

class Email extends Component {
  componentDidMount() {
    const { prefilled, currentUserInformations } = this.props
    prefilled('contactEmail', currentUserInformations.recipientEmail)
    prefilled('contactPhoneNumber', currentUserInformations.recipientPhoneNumber)
    prefilled('contactFirstname', currentUserInformations.recipientFirstname)
    prefilled('contactLastname', currentUserInformations.recipientLastname)
  }

  render() {
    const { handleSubmit } = this.props;
      return (
        <div>
          <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>
          {translate("email_input")}
          </DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={0} direction="row">
                <Grid container item spacing={0} justifyContent="center" >
                  <Grid item xs={10} md={4}>
                    <Field  id="contact-email"
                            name="contactEmail"
                            label="Email"
                            style={{width: '95%'}}
                            component={RenderTextField}/>
                  </Grid>
                  <Grid item xs={10} md={4}>
                    <Field id="contact-phone-number"
                           style={{width: '95%'}}
                           name="contactPhoneNumber"
                           label={translate('phone')}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={0} direction="row">
                <Grid container item spacing={0} justifyContent="center" >
                  <Grid item xs={10} md={4}>
                    <Field id="contact-firstname"
                           style={{width: '95%'}}
                           name="contactFirstname"
                           label="Nom"
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                  <Grid item xs={10} md={4}>
                    <Field id="contact-lastname"
                           style={{width: '95%'}}
                           name="contactLastname"
                           label="Prénom"
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </div>
      )
    }
  }

const loginSelector = formValueSelector('login')

const mapStateToProps = state => ({
  email : loginSelector(state, 'email'),
  currentUserInformations: state.returnItem.currentUserInformations
})

const mapDispatchToProps = dispatch => ({
  prefilled: (field, value) =>
    dispatch(change('emailFormCourier', field, value))
})

Email = reduxForm({
  form: 'emailFormCourier',
  onSubmit: submit
})(Email)

export default connect(mapStateToProps, mapDispatchToProps)(Email);
