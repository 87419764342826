import {
  ADD_USER_COMMANDS,
  RESET_SWIPEABLE_INDEX,
  HANDLE_CHANGE_SWIPEABLE_INDEX,
  SET_SELECTED_ITEM,
  OPEN_RETURN_MODAL,
  CLOSE_RETURN_MODAL,
  SET_STEPS_ENUM,
  SET_RETURN_NATURE_CODE,
  SET_ACTION_ITEM,
  SET_EXPAND_CARD,
  SET_SWITCHABLE_PRODUCT,
  SET_EXTERNAL_COMPANY_ID,
  SET_DESIRED_ITEM,
  SET_RETURN_SLIP_URL,
  OPEN_CONFIRMATION_CARRIER_MODAL,
  CLOSE_CONFIRMATION_CARRIER_MODAL,
  OPEN_CONFIRMATION_COURIER_MODAL,
  CLOSE_CONFIRMATION_COURIER_MODAL,
  SUCCESS_CREATE_RETURN,
  LOADING_CREATE_RETURN,
  ADD_CURRENT_ITEMS,
  ADD_CURRENT_USER_INFORMATIONS,
  RESET_STATE,
  ADD_EXCHANGE_INFORMATIONS,
  OPEN_CONFIRMATION_PICKUP_MODAL,
  CLOSE_CONFIRMATION_PICKUP_MODAL
} from './actions'

const initialState = {
  returnLabelUrl: '',
  switchableProducts: [],
  userCommands: [],
  commandItems: [],
  swipeableIndex: 0,
  openReturnModal: false,
  enumIndex: 1,
  stepsEnum: [],
  returnNatureCode: '',
  companyExternalId: '',
  desiredItem: {},
  selectedItem: {
   pictures: [{}],
  },
  successCreateReturn: false,
  loadingCreateReturn: false,
  displayConfirmationCarrierModal: false,
  displayConfirmationCourierModal: false,
  displayConfirmationPickupModal: false,
  bookingTimeToken: '',
  currentUserInformations: {},
  exchangeInformations: {}
}

const returnItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return initialState
    case LOADING_CREATE_RETURN:
      return {
        ...state,
        loadingCreateReturn: action.data
      }
    case ADD_EXCHANGE_INFORMATIONS:
      return {
        ...state,
        exchangeInformations: action.exchangeInformations
      }
    case ADD_CURRENT_USER_INFORMATIONS:
      return {
        ...state,
        currentUserInformations: action.currentUserInformations
      }
    case SUCCESS_CREATE_RETURN:
      return {
        ...state,
        successCreateReturn: true
      }
    case OPEN_CONFIRMATION_CARRIER_MODAL:
      return {
        ...state,
        displayConfirmationCarrierModal: true
      }
    case CLOSE_CONFIRMATION_CARRIER_MODAL:
      return {
        ...state,
        displayConfirmationCarrierModal: false
      }
    case OPEN_CONFIRMATION_PICKUP_MODAL:
      return {
        ...state,
        displayConfirmationPickupModal: true
      }
    case CLOSE_CONFIRMATION_PICKUP_MODAL:
      return {
        ...state,
        displayConfirmationPickupModal: false
      }    
    case OPEN_CONFIRMATION_COURIER_MODAL:
      return {
        ...state,
        displayConfirmationCourierModal: true
      }
    case CLOSE_CONFIRMATION_COURIER_MODAL:
      return {
        ...state,
        displayConfirmationCourierModal: false
      }
    case SET_RETURN_SLIP_URL:
      return {
        ...state,
        returnLabelUrl: action.returnLabelUrl
      }
    case ADD_USER_COMMANDS:
      return {
        ...state,
        userCommands: action.userCommands
      }
    case ADD_CURRENT_ITEMS:
    return {
      ...state,
      commandItems: action.items,
      commandRandomId: action.id
    }
    case RESET_SWIPEABLE_INDEX:
      return {
        ...state,
        enumIndex: 1,
        swipeableIndex: 0
      }
    case HANDLE_CHANGE_SWIPEABLE_INDEX:
      return {
        ...state,
        enumIndex: state.enumIndex + 1,
        swipeableIndex: state.stepsEnum[state.enumIndex].index
      }
    case SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem
      }
    case OPEN_RETURN_MODAL:
      return {
        ...state,
        openReturnModal: true
      }
    case CLOSE_RETURN_MODAL:
      return {
        ...state,
        openReturnModal: false
      }
    case SET_EXTERNAL_COMPANY_ID:
      return {
        ...state,
        companyExternalId: action.companyExternalId
      }
    case SET_STEPS_ENUM:
      return {
        ...state,
        stepsEnum: action.stepsEnum
      }
    case SET_RETURN_NATURE_CODE:
      return {
        ...state,
        returnNatureCode: action.returnNatureCode
      }
    case SET_EXPAND_CARD:
      return {
        ...state,
        commandItems: state.commandItems.map((item) => {
          // Find the item with the matching id
          if(item.index === state.selectedItem.index) {
            return {
              ...item,  // copy the existing item
              expanded: true,
            }
          }
          return item;
        })
      }
    case SET_ACTION_ITEM:
      return {
        ...state,
        commandItems: state.commandItems.map((item) => {
          // Find the item with the matching id
          if(item.index === action.actionItem.index) {
            // Return a new object
            return {
              ...item,  // copy the existing item
              returnNatureCode: action.actionItem.returnNatureCode,
              customReturnReason: action.actionItem.customReturnReason,
              returnReason: action.actionItem.returnReason,
              returnSecondaryReason: action.actionItem.returnSecondaryReason,
              desiredItem: action.actionItem.returnNatureCode !== 'exchange' ? {} : action.actionItem.desiredItem
            }
          }
          // Leave every other item unchanged
          return item;
        })
      }
      case SET_SWITCHABLE_PRODUCT:
        return {
          ...state,
          switchableProducts: action.switchableProducts
        }
      case SET_DESIRED_ITEM:
        return {
          ...state,
          desiredItem: action.desiredItem
        }
    default:
      return state
    }
  }
export default returnItemReducer;
