import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Alert = () => {
  return (
    <div>
      <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>
        {
          "Êtes-vous sûr d'avoir terminé ?"
        }
      </DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <DialogContentText id="alert-dialog-slide-description">
          Une fois validé il vous sera impossible de faire une nouvelle modification
          sur votre commande
        </DialogContentText>
      </DialogContent>
    </div>
  )
}

export default Alert
