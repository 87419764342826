import { login } from "../../../api/api";
import { SubmissionError } from "redux-form";
import mappingErrors from "../../utilis/api/mappingErrors";
import { addUserCommands } from "../../returnItems/actions";
import {
  setSwipeableIndex,
  setLoadingAuth,
  storeRecipeientCriteria
} from "../actions";
import errorCatcher from "../../utilis/api/errorCatcher";

import translate from '../../../i18n/translate';

/**
 * @description
 * Function permettant la validation asynchrone du formulaire
 * de validation, ajoute les données d'authentification aux requêtes
 * @param { object } values - Valeur des champs du formulaire
 * @param { function } dispatch - fonction dispatch de reduxselectedCommandStep
 * @param { object } props - props
 */

const asyncValidate = (values, dispatch, props) => {
  const { recipientCriteria, externalReference } = values;
  dispatch(setLoadingAuth(true));
  dispatch(storeRecipeientCriteria(recipientCriteria));
  if (!values.externalReference || !values.recipientCriteria) {
    dispatch(setLoadingAuth(false));
    throw new SubmissionError({
      externalReference: values.externalReference ? "" : translate('required'),
      recipientCriteria: values.recipientCriteria ? "" : translate('required')
    });
  }
  return login({ recipientCriteria, externalReference }).then(
    res => {
      if (res.data.success === true) {
        dispatch(setSwipeableIndex(1));
        dispatch(addUserCommands(res.data.commands));
        dispatch(setLoadingAuth(false));
      } else {
        dispatch(setLoadingAuth(false));
        throw new SubmissionError({
          externalReference: mappingErrors[res.data.errorCode]
        });
      }
    },
    error => {
      dispatch(setLoadingAuth(false));
      if (error.status === 500) {
        errorCatcher(error.response.status, props.history);
      }
      throw new SubmissionError({
        externalReference: mappingErrors[error.response.data.errorCode]
      });
    }
  );
};

export default asyncValidate;
